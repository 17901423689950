/*
 - Mobile: 576px
- Tablet: 980px
- Desktop: 1400px
*/

/*
Usage
*selector{
    @include breakpoint-up(medium){
        font-size: 2rem
    }
}
*/

$breakpoints-up: (
    'medium' : '576px',
    'large' : '980px',
    'xlarge' : '1400',
);

$breakpoints-down:(
    'xsmall' : '300px',
    'small' : '575px',
    'medium' : '979px',
    'large' : '1400px',
);

@mixin breakpoint-up($size){
    @media only screen and (min-width: map-get($breakpoints-up, $size)){
        @content;
    } 
};

@mixin breakpoint-down($size){
    @media only screen and (max-width: map-get($breakpoints-down, $size)){
        @content;
    }
}

