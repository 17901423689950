:root {
    --primary-50: 210, 56%, 93%;
    --primary-100: 210, 57%, 74%;
    --primary-200: 210, 57%, 66%;
    --primary-300: 210, 57%, 59%;
    --primary-400: 210, 57%, 54%;
    --primary-500: 210, 56%, 47%;
    --primary-600: 210, 57%, 40%;
    --primary-700: 210, 57%, 27%;
    --primary-800: 209, 57%, 20%;
    --primary-900: 210, 56%, 13%;

    --accent-50: 31, 94%, 88%;
    --accent-100: 32, 92%, 81%;
    --accent-200: 32, 92%, 67%;
    --accent-300: 32, 92%, 67%;
    --accent-400: 32, 92%, 61%;
    --accent-500: 32, 92%, 54%;
    --accent-600: 32, 93%, 47%;
    --accent-700: 32, 92%, 35%;
    --accent-800: 32, 93%, 27%;
    --accent-900: 32, 92%, 21%;

    --neutral-50: 210, 40%, 98%;
    --neutral-100: 214, 8%, 83%;
    --neutral-200: 218, 7%, 76%;
    --neutral-300: 210, 6%, 69%;
    --neutral-400: 212, 6%, 54%;
    --neutral-500: 212, 6%, 46%;
    --neutral-600: 210, 11%, 32%;
    --neutral-700: 212, 15%, 25%;
    --neutral-800: 211, 24%, 17%;
    --neutral-900: 213, 44%, 10%;

    // Status Colors
    --error-50: hsl(356, 100%, 97%);
    --error-400: hsl(351, 95%, 71%);
    --error-600: hsl(347, 77%, 50%);

    --warning-50: hsl(48, 96%, 89%);
    --warning-400: hsl(38, 92%, 50%);
    --warning-600: hsl(32, 95%, 44%);

    --success-50: hsl(166, 76%, 97%);
    --success-400: hsl(172, 66%, 50%);
    --success-600: hsl(175, 84%, 32%);

    // Basic Colors
    --basic-black: #252525;
    --basic-white: #f5f5f5;
}
