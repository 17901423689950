@use "../abstract" as *;

.services {
    margin-bottom: rem(100);
    &__wrapper {
        display: block;
    }

    &__content {
        display: grid;
        grid-template-rows: auto 1fr auto;
        margin: rem(16) 0;
        padding: rem(16);
        border-radius: rem(12);
        box-shadow: var(--shadow-2xl);

        row-gap: rem(16);

        @include breakpoint-up("large") {
            grid-template-columns: auto 1fr;
            column-gap: rem(36);
            row-gap: 0;
        }
    }

    &__select {
        --_border-radius: var(--rounded-sm);
        --_clr-border: hsl(var(--primary-700));
        --_clr-label: hsl(var(--primary-700));
        --_clr-bg: hsl(var(--primary-50));

        .select-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: rem(59);
            width: 100%;
            color: var(--_clr-label);
            background-color: var(--_clr-bg);
            font-size: var(--text-base);
            font-weight: var(--font-semibold);
            padding: 0 rem(10);
            border: 1px solid var(--_clr-border);
            border-radius: var(--rounded-sm);
            cursor: pointer;

            i {
                font-size: rem(18);
            }
        }

        .content {
            --_clr-srh-border: hsl(var(--neutral-300));
            --_clr-srh-label: hsl(var(--neutral-400));

            background-color: var(--_clr-bg);
            border-radius: var(--_border-radius);
            padding: rem(20) rem(20) rem(1) rem(20);
            margin-top: rem(15);
            transition: display 3s ease-in-out;
            display: none;

            .search {
                position: relative;

                i {
                    position: absolute;
                    font-size: rem(24);
                    color: var(--_clr-srh-label);
                    left: rem(15);
                    top: 50%;
                    transform: translateY(-50%);
                }

                input {
                    height: rem(53);
                    width: 100%;
                    color: var(--_clr-srh-label);
                    border: 1px solid var(--_clr-srh-border);
                    border-radius: rem(5);
                    padding: 0 rem(15) 0 rem(43);
                }
            }

            .options {
                margin-top: rem(10);
                max-height: rem(250);
                padding: 0;
                overflow-y: auto;
                color: var(--_clr-label);

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 25px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #ccc;
                    border-radius: 25px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #b3b3b3;
                }

                li {
                    display: flex;
                    align-items: center;
                    padding: 0 rem(13);
                    height: rem(50);
                    list-style-type: none;
                    border-radius: var(--_border-radius);
                    cursor: pointer;
                    transition: all 10ms ease-in;
                    position: relative;

                    &:hover {
                        background-color: hsl(var(--primary-100), 0.2);
                    }

                    &:active,
                    &:focus,
                    &:focus-visible,
                    &[data-selected="true"] {
                        background-color: hsl(var(--primary-100), 0.45);
                    }
                }
            }
        }

        &[data-visible] .content {
            display: block;
        }

        @include breakpoint-up("large") {
            --_border-radius: var(--rounded-sm);
            --_clr-border: hsl(var(--primary-700));
            --_clr-label: hsl(var(--primary-700));
            --_clr-bg: transparent;
            --_clr-li-bg: hsl(var(--primary-50));

            .select-btn {
                display: none;
            }

            .content {
                // height: rem(790);
                width: rem(286);
                display: block;
                margin-top: 0;
                padding: 0;

                .search {
                    display: none;
                }

                .options {
                    max-height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: rem(2);
                    font-weight: var(--font-semibold);
                    height: 100%;
                    margin: 0;

                    li {
                        background-color: var(--_clr-li-bg);
                        border-radius: 0 var(--_border-radius) var(--_border-radius) 0;
                        position: relative;
                        transition: background-color 100ms ease-in-out;
                        height: rem(64);

                        &:hover {
                            background-color: hsl(var(--primary-500), 0.25);
                        }

                        &:active,
                        &:focus,
                        &:focus-visible,
                        &[data-selected="true"] {
                            background-color: hsl(var(--primary-500));
                            color: hsl(var(--primary-50));

                            &::before {
                                content: "";
                                position: absolute;
                                width: rem(4);
                                top: 0;
                                bottom: 0;
                                left: 0;
                                background-color: hsl(var(--accent-500));
                            }
                        }
                    }
                }
            }
        }
    }

    &__info {
        --_clr-title: hsl(var(--primary-800));
        --_clr-subtitle: hsl(var(--neutral-400));
        --_clr-body: hsl(var(--primary-900));
        --_clr-divider: hsl(var(--neutral-400));
        --_icon: url("../../assets/images/logo_sm/dark-logo.png");

        .header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: rem(8);
            padding-bottom: rem(16);

            * {
                width: 100%;
            }

            h4 {
                font-size: var(--text-3xl);
                font-weight: var(--font-semibold);
                color: var(--_clr-title);
            }

            h5 {
                font-size: var(--text-sm);
                font-weight: var(--font-semibold);
                color: var(--_clr-subtitle);
                margin: 0;
            }

            .info-divider {
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                position: relative;
                color: var(--_clr-divider);
                width: rem(16);
                height: rem(16);

                .divider-icon {
                    background-image: var(--_icon);
                    background-size: contain;
                    background-repeat: no-repeat;
                    aspect-ratio: 1;
                    width: 100%;
                    color: var(--_clr-divider);
                }

                &::before,
                &::after {
                    content: "";
                    height: rem(3);
                    width: rem(36);
                    background-color: var(--_clr-divider);
                    position: absolute;
                    top: 50%;
                    margin-top: -1px;
                }
                &::before {
                    left: auto;
                    right: 150%;
                }
                &::after {
                    left: 150%;
                    right: auto;
                }
            }

            @include breakpoint-up("large") {
                align-items: left;
                text-align: left;
                gap: 0;

                h5 {
                    font-size: var(--text-base);
                }

                .info-divider {
                    display: none;
                }
            }
        }

        .body {
            font-size: var(--text-sm);
            color: var(--_clr-body);
            line-height: 1.75;

            h6 {
                margin: 0;
                padding-top: rem(24);
                padding-bottom: rem(6);
                font-weight: 600;
                color: var(--_clr-title);
            }

            p {
                font-size: inherit;
                color: inherit;
            }

            li {
                text-align: justify;
                padding: rem(5) 0;
                padding-right: rem(20);
            }

            @include breakpoint-up("large") {
                font-size: var(--text-base);
                line-height: 1.5;
            }
        }
    }

    &__carousel {
        padding: rem(16) 0;
        .s-carousel {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: rem(5);
            justify-content: space-around;
            width: 100%;

            .slide-img {
                width: 100%;
                height: rem(255);
                cursor: pointer;

                .img {
                    height: 100%;
                    width: 100%;
                    border-radius: 5px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            @include breakpoint-up("large") {
                flex-direction: row;

                .slide-img {
                    width: rem(277);
                }
            }
        }
    }

    &__actions {
        grid-column: 1/-1;

        .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: rem(24);
            padding-top: rem(34);

            a {
                display: flex;
                gap: rem(8);
                width: 100%;
                max-height: rem(57);
                max-width: rem(325);
                padding: rem(16) rem(24);
            }
        }

        @include breakpoint-up("large") {
            .wrapper {
                justify-content: flex-end;
                flex-direction: row;
                gap: rem(20);
                padding-right: 0;

                a {
                    max-height: auto;
                    max-width: auto;
                    width: auto;
                }
            }
        }
    }
}
