@use "../abstract" as *;

html{
    overflow-x: hidden;
}

body{
    position: relative;    
}

.container{
    // outline: 2px solid yellowgreen;
    padding: 0 rem(16);
    max-width: rem(1550);
    margin: 0 auto;

    @include breakpoint-up('large'){
        padding: 0 rem(64);
    }
}