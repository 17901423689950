@use "../abstract" as *;

:root {
    // Mobile Font Size
    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-base: 1rem;
    --text-lg: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-5xl: 3rem;

    // Line Spacing
    --leading-tight: 1.25;
    --leading-normal: 1.5;
    --leading-loose: 2;

    // Font Weight
    --font-normal: 400;
    --font-semibold: 600;
    --font-bold: 800;

    --font-mont: "Montserrat", sans-serif;

    @include breakpoint-up("medium") {
        // Desktop Font Size
        --text-5xl: 5rem;
    }
}
