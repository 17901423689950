/* Firefox */
:root {
    --_clr-scrollbar: hsl(var(--primary-500));
    --_clr-hover-scrollbar: hsl(var(--primary-600));
    --_clr-active-scrollbar: hsl(var(--primary-700));
    --_w-scrollbar: 6px;

    --_clr-track: hsl(var(--primary-50));
    --_clr-border-track: hsl(var(--primary-50));
    --_clr-hover-track: hsl(var(--neutral-100));
    --_clr-active-track: hsl(var(--neutral-100));
}

* {
    scrollbar-width: thin;
    scrollbar-color: #362175 #dfe9eb;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: var(--_w-scrollbar);
}
*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: var(--_clr-track);
    border: 1px solid var(--_clr-border-track);
}

*::-webkit-scrollbar-track:hover {
    background-color: var(--_clr-hover-track);
}

*::-webkit-scrollbar-track:active {
    background-color: var(--_clr-active-track);
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--_clr-scrollbar);
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--_clr-hover-scrollbar);
}

*::-webkit-scrollbar-thumb:active {
    background-color: var(--_clr-active-scrollbar);
}
