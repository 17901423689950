@use "../abstract" as *;

.cardpop {
    --_clr-card: var(--success-50);
    --_clr-title: var(--success-600);
    --_clr-icon: var(--success-600);
    --_clr-para: var(--neutral-900);

    //Theme
    &--error {
        --_clr-card: var(--error-50);
        --_clr-title: var(--error-600);
        --_clr-icon: var(--error-600);
        --_clr-para: var(--neutral-900);
    }

    background-color: hsl(0, 0%, 0%, 0.01);
    border-radius: 8px;
    -webkit-backdrop-filter: blur(9.4px);
    backdrop-filter: blur(9.4px);
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 99;
    inset: 0;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(17);
        background-color: var(--_clr-card);
        padding: rem(60) rem(18);
        max-width: rem(338);
        border-radius: var(--rounded-sm);
        position: relative;
        z-index: 999;
        animation: pop-in 500ms;
    }

    &__exit {
        &::after {
            font-family: "boxicons";
            content: "\ec8d";
            position: absolute;
            right: rem(15);
            top: rem(10);
            font-size: rem(25);
            color: var(--neutral-900);
            cursor: pointer;
            transition: all 200ms ease-in-out;
        }

        &:hover::after {
            font-size: rem(26);
        }
    }

    &__icon {
        color: var(--_clr-icon);
    }

    &__title {
        color: var(--_clr-title);
        font-weight: var(--font-semibold);
        text-align: center;
        font-size: var(--text-3xl);
    }

    &__para {
        color: var(--_clr-para);
        font-weight: var(--font-semibold);
        text-align: center;
    }
}

.cardpop[data-visible="false"] {
    display: none;
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
