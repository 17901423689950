@use "../abstract" as *;

.aboutUs {
    &__wrapper {
    }

    &__header {
        h2 {
            font-size: var(--text-3xl);

            @include breakpoint-up("large") {
                font-size: var(--text-4xl);
            }
        }

        p {
            font-size: var(--text-sm);

            @include breakpoint-up("large") {
                font-size: var(--text-base);
            }
        }
    }

    &__contentWrapper {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        row-gap: rem(28);
        padding-bottom: rem(48);
        // outline: 2px solid blue;
        padding-top: rem(20);

        @include breakpoint-up("large") {
            grid-template-columns: 1fr 1fr;
            column-gap: rem(48);
            row-gap: 0;
        }

        .img-container {
            width: 100%;
            height: rem(250);

            @include breakpoint-up("large") {
                height: 100%;
            }

            .img {
                height: 100%;
                width: 100%;
                border-radius: var(--rounded-sm);
                display: block;
                object-fit: cover;
                object-position: center;
                background-repeat: no-repeat;

                @include breakpoint-up("medium") {
                    object-position: right;
                }
            }
        }

        .contents {
            display: flex;
            flex-direction: column;
            gap: rem(28);

            @include breakpoint-up("large") {
                gap: rem(32);
            }

            .content {
                h3 {
                    font-size: var(--text-2xl);
                    font-weight: 600;
                    text-transform: uppercase;
                    color: hsl(var(--accent-600));
                    padding-bottom: rem(8);

                    @include breakpoint-up("large") {
                        font-size: var(--text-3xl);
                    }
                }

                p,
                ul {
                    color: hsl(var(--neutral-600));
                    font-size: var(--text-sm);
                    text-align: justify;

                    @include breakpoint-up("large") {
                        font-size: var(--text-base);
                    }
                }

                // UL IS IN BASE.SCSS, ARROW-LIST
            }
        }
    }
}
