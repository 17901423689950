@use "../abstract" as *;

.default-carousel {
    width: 100%;
    outline: 2px solid pink;
    height: 100%;
    position: relative;     
    overflow: hidden;

    &__content {
        display: inline-flex;
        // width: 100%;
    }

    &__slide {
        display: inline;
        // position: absolute;
        // inset: 0;
        width: 100%;
        // height: 100%;
        transition: all 1s ease-in;
    }

    &__info {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        

        img {
            background-size: cover;
            background-position: center;
            filter: opacity(0.7);
        }
    }

    button[data-carousel-next],
    button[data-carousel-prev] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        background-color: transparent;
        border: none;
        padding: 0;

        i {
            font-size: 2.5rem;
            font-weight: 200;
            color: white;
            transition: all 300ms ease-in-out;

            &:hover {
                font-size: 2.8rem;
                font-weight: 400;
            }
        }
    }

    button {
        &[data-carousel-next] {
            right: 10px;
        }
        &[data-carousel-prev] {
            left: 10px;
        }
    }
}

// @media (max-width: 640px) {

//     .carousel {
//         &__slide {
//             display: flex;
//             flex-direction: column;
//         }

//         &__info {
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             text-align: center;
//             width: 100%;
//             padding-left: 0;
//         }

//         button[data-carousel-next],
//         button[data-carousel-prev] {
//             i {
//                 font-size: 1.5rem;

//                 &:hover {
//                     font-size: 1.6rem;
//                 }
//             }
//         }

//         button {
//             &[data-carousel-next] {
//                 right: 5px;
//             }
//             &[data-carousel-prev] {
//                 left: 5px;
//             }
//         }
//     }
// }
