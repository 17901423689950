@use "../abstract" as *;

.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    max-height: rem(44);
    gap: rem(0);
    font-size: rem(14);
    font-weight: var(--font-semibold);
    border-radius: var(--rounded-sm);
    padding: rem(8) rem(12);
    cursor: pointer;
    transition: all 200ms ease-in-out;

    background-color: var(--bg-color);
    color: var(--text-color);
    &:hover {
        background-color: var(--darker-bg-color);
    }

    i {
        color: var(--text-color);
        font-size: rem(25);
        text-align: center;
        line-height: 0;
        transition: all 200ms ease-in-out;
    }

    &--full {
        width: 100%;
    }

    @include breakpoint-up("medium") {
        font-size: rem(16);
        padding: 10px 16px;
    }

    // Themes
    &--primary {
        // Config Colors
        --bg-color: hsla(var(--primary-500), 1);
        --darker-bg-color: hsla(var(--primary-600), 1);
        --text-color: hsla(var(--primary-50), 1);
    }

    &--secondary {
        // Config Colors
        --bg-color: hsla(var(--accent-500), 1);
        --darker-bg-color: hsla(var(--accent-600), 1);
        --text-color: hsla(var(--neutral-50), 1);
    }

    &--neutral {
        // Config Colors
        --bg-color: hsla(var(--neutral-600), 1);
        --darker-bg-color: hsla(var(--neutral-700), 1);
        --text-color: hsla(var(--neutral-50), 1);
    }

    // Styles
    &--outline {
        color: var(--bg-color);
        background-color: transparent;
        border: 1px solid var(--bg-color);

        i {
            color: var(--bg-color);
        }

        &:hover {
            color: var(--text-color);
            background-color: var(--bg-color);

            i {
                color: var(--text-color);
            }
        }
    }

    &--link {
        color: var(--bg-color);
        background-color: transparent;
        padding: 0;
        font-size: rem(16);

        i {
            color: var(--bg-color);
        }

        &:hover {
            background-color: transparent;
            color: var(--darker-bg-color);

            i {
                color: var(--darker-bg-color);
            }
        }
    }
}

button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

button[loading="true"] {
    cursor: wait;
    display: flex;
    gap: 0.5rem;
    background-color: var(--darker-bg-color);
    
    span {
        visibility: hidden;
    }
    
    &::before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: conic-gradient(#0000 10%, white);
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
        mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
        animation: s3 1s infinite linear;
        transition: all 1050ms ease-out;
    }
}

@keyframes s3 {
    to {
        transform: rotate(1turn);
    }
}
