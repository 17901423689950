@use "../abstract" as *;

.hero {
    margin-bottom: rem(64);

    @include breakpoint-up("medium") {
        margin-bottom: rem(90);
    }
    &__wrapper {
        position: relative;
        display: grid;

        @include breakpoint-up("medium") {
            background-color: hsl(var(--primary-50));
            grid-template-columns: 1.5fr 2fr;
            align-items: center;
        }
    }

    &__bg-image {
        height: 100%;
        background-image: url("../../assets/images/mobile_hero_worker.webp");
        background-color: hsl(var(--neutral-400));
        background-size: cover;
        background-blend-mode: multiply;
        background-position-x: center;
        background-repeat: no-repeat;
        margin-left: rem(-64);
        position: absolute;
        z-index: -1;
        inset: 0;
        animation: fade-in 500ms ease-in-out both;

        @include breakpoint-up("medium") {
            background-image: url("../../assets/images/hero_worker_3000.webp");
            background-color: transparent;
            z-index: 1;
            position: unset;
        }
    }

    &__bg-logo-pattern {
        background-image: url("../../assets/images/logo_pattern.webp");
        background-size: contain;
        background-repeat: no-repeat;
        width: rem(160);
        aspect-ratio: 1;
        position: absolute;
        bottom: -15%;
        z-index: 1;
        right: 0;
        filter: brightness(0) saturate(100%) invert(22%) sepia(9%) saturate(5736%) hue-rotate(176deg) brightness(93%)
            contrast(87%);

        @include breakpoint-up("medium") {
            width: rem(250);
            bottom: -20%;
        }
        @include breakpoint-up("large") {
            width: rem(350);
        }
    }

    &__center-image {
        display: none;

        @include breakpoint-up("medium") {
            display: block;
            position: absolute;
            aspect-ratio: 1;
            height: rem(307);
            top: 60%;
            left: 50%;
            transform: translate(-70%, -60%);
            z-index: 10;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: rem(16);
        padding-top: rem(143);
        padding-bottom: rem(101);
        color: hsl(var(--primary-50));
        z-index: 100;

        h1 {
            font-weight: var(--font-semibold);
            font-size: clamp(var(--text-4xl), -0.3714rem + 7.2816vw, var(--text-5xl));
            animation: fade-down 500ms ease-in-out both;
        }

        p {
            font-weight: var(--font-semibold);
            font-size: clamp(var(--text-sm), 0.2917rem + 0.9524vw, var(--text-lg));
            text-align: justify;
            line-height: var(--leading-normal);
            animation: fade-in 1000ms ease-in-out both;
            animation-delay: 550ms;
        }

        .cta {
            animation: fade-in 1000ms ease-in-out both;
            animation-delay: 800ms;
        }

        @include breakpoint-up("medium") {
            padding-top: rem(170);
            padding-bottom: rem(141);
            padding-left: rem(30);
            color: hsl(var(--primary-700));
            h1 {
                font-weight: var(--font-bold);
            }
            z-index: 0;
        }
        @include breakpoint-up("large") {
            /* top | right | bottom | left */
            padding-top: rem(203);
            padding-bottom: rem(121);
            padding-left: rem(96);
        }
    }
    .bgImageContent {
        display: none;
        visibility: none;

        @include breakpoint-up("medium") {
            display: unset;
            visibility: unset;
            position: absolute;
            background-size: cover;
            background-repeat: no-repeat;
            width: 59%;
            height: 100%;
            right: 0;
            top: -89px;
            opacity: 0.15;
            z-index: -1;
            filter: brightness(0) saturate(100%) invert(22%) sepia(9%) saturate(5736%) hue-rotate(176deg)
                brightness(93%) contrast(87%);
        }
    }
}

@keyframes fade-down {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
