@use "../abstract" as *;

body {
    overflow: hidden;
}

.loading-welcome[data-loading="true"] {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #faf9f6;
    z-index: 9999998;
    opacity: 1;
    transition: all 2s ease-in;

    & ~ .App {
        display: none;
        // visibility: none;
        // cursor: hidden;
    }
}

.loading-welcome[data-loading="false"] {
    display: none;
    & ~ .App {
        display: block;
        visibility: unset;
        cursor: unset;
    }
}


.logoWrapper {
    display: grid;
    place-content: center;
    margin: auto;
    height: 100vh;
    width: 100%;
}

.logoWrapper img {
    max-width: 100px;
    animation: pulse_animation 2500ms ease-in-out forwards;
    transform-origin: 50% 50%;

    @include breakpoint-down("small") {
        margin-bottom: 150px;
    }
}

.progress {
    --_clr-progress: #454545;
    --_clr-bg-progress: #00a6e7;
    height: 3px;
    width: 100%;
    background: linear-gradient(var(--_clr-progress) 0 0), linear-gradient(var(--_clr-progress) 0 0),
        var(--_clr-bg-progress);
    background-size: 60% 100%;
    background-repeat: no-repeat;
    animation: swipe_animation 3s infinite;
}

@keyframes swipe_animation {
    0% {
        background-position:
            -150% 0,
            -150% 0;
    }

    66% {
        background-position:
            250% 0,
            -150% 0;
    }

    100% {
        background-position:
            250% 0,
            250% 0;
    }
}

@keyframes pulse_animation {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1.4);
    }
}
