@use "../abstract" as *;

.pageHeader {
    // outline: 2px solid red;
    padding-top: 2rem;
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(12);

    &__title {
        font-size: var(--text-2xl);
        text-transform: uppercase;
        font-weight: 600;
        color: hsl(var(--accent-500));

        @include breakpoint-up("large") {
            font-size: var(--text-4xl);
        }
    }

    &__para {
        text-align: center;
        max-width: 60ch;
        font-weight: 600;
        font-size: var(--text-base);
        color: hsl(var(--primary-800));
        text-justify: auto;

        @include breakpoint-up("large") {
            font-size: var(--text-lg);
        }
    }
}
