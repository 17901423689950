@use "../abstract" as *;

.seperator {
    --_clr-divider: hsl(var(--neutral-400));
    --_icon: url("../../assets/images/logo_sm/dark-logo.png");
    --_w-divider: 89px;
    --_h-divider: 2px;

    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative;
    color: var(--_clr-divider);
    width: rem(16);
    height: rem(16);

    &__icon {
        background-image: var(--_icon);
        background-size: contain;
        background-repeat: no-repeat;
        aspect-ratio: 1;
        width: 100%;
        color: var(--_clr-divider);
    }

    &::before,
    &::after {
        content: "";
        height: var(--_h-divider);
        width: var(--_w-divider);
        background-color: var(--_clr-divider);
        position: absolute;
        top: 50%;
        margin-top: -1px;
    }
    &::before {
        left: auto;
        right: 150%;
    }
    &::after {
        left: 150%;
        right: auto;
    }

    // Sizes
    &--lg{
        --_w-divider: 89px;
        --_h-divider: 2px;

        @include breakpoint-up('medium'){
            --_w-divider: 151px;
        }
    }
    &--sm{
        --_w-divider: 36px;
        --_h-divider: 2.5px;
    }

    // Themes
    &--light {
        --_clr-divider: hsl(var(--primary-50));
        --_icon: url("../../assets/images/logo_sm/light-logo.png");
    }
    &--dark {
        --_clr-divider: hsl(var(--neutral-400));
        --_icon: url("../../assets/images/logo_sm/dark-logo.png");
    }
}
