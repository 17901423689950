@use "../abstract" as *;

.section {
     
  
     // Themes

        --_bg-color: hsl(var(--primary-50));
        --_bg-image: url("../../assets/images/map_dark.png");
        --_bg-opacity: 0.1;
        --_clr-title: hsl(var(--primary-900));
        --_clr-para: hsl(var(--neutral-400));
        --_clr-divider: hsl(var(--neutral-400));
        --_icon: url("../../assets/images/logo_sm/dark-logo.png");
        

     &--dark {
        --_bg-color: hsl(var(--primary-800));
        --_bg-image: url("../../assets/images/map_light.png");
        --_bg-opacity: 0.15;
        --_clr-title: hsl(var(--primary-50));
        --_clr-para: hsl(var(--neutral-200));
        --_clr-divider: hsl(var(--neutral-200));
        --_icon: url("../../assets/images/logo_sm/light-logo.png");
    }
    &--light {
        --_bg-color: hsl(var(--neutral-50));
        --_bg-image: url("../../assets/images/map_light.png");
        --_bg-opacity: 0.03;
        --_clr-title: hsl(var(--primary-900));
        --_clr-para: hsl(var(--neutral-400));
        --_clr-divider: hsl(var(--neutral-400));
        --_icon: url("../../assets/images/logo_sm/dark-logo.png");
    }

    position: relative;
    overflow: hidden;
    background-color: var(--_bg-color);
    width: 100%;
    padding-top: rem(62);
    padding-bottom: rem(62);

    &__bg {
        // background-image: var(--_bg-image);
        width: 100%;
        height: 100%;

        img {
            background-size: contain;
            background-repeat: no-repeat;
            opacity: var(--_bg-opacity);
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 1;
        }

        &--sm {
            img {
                width: rem(392);
            }

            @include breakpoint-up("medium") {
                img {
                    width: rem(692);
                }
            }
        }

        &--bottom {
            img {
                bottom: 0;
            }
        }

        &--top {
            img {
                top: rem(5);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-content: center;
        position: relative;
        z-index: 10;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: rem(40);
        gap: rem(8);

        h2 {
            color: var(--_clr-title);
            font-size: var(--text-4xl);
            font-weight: var(--font-semibold);
            text-align: center;
        }

        &-divider {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            position: relative;
            color: var(--_clr-divider);
            width: rem(16);
            height: rem(16);

            .divider-icon {
                background-image: var(--_icon);
                background-size: contain;
                background-repeat: no-repeat;
                aspect-ratio: 1;
                width: 100%;
                color: var(--_clr-divider);
            }

            &::before,
            &::after {
                content: "";
                height: rem(3);
                width: rem(36);
                background-color: var(--_clr-divider);
                position: absolute;
                top: 50%;
                margin-top: -1px;
            }
            &::before {
                left: auto;
                right: 150%;
            }
            &::after {
                left: 150%;
                right: auto;
            }
        }

        p {
            font-size: var(--text-sm);
            font-weight: var(--font-semibold);
            line-height: var(--leading-tight);
            text-align: center;
            color: var(--_clr-para);
            max-width: rem(593);
        }
    }

    // Headings
    &--heading-2 {
        
        .section__header {
            --_clr-title: hsl(var(--accent-600));
            --_clr-para: hsl(var(--neutral-50));
            h2 {
                font-size: var(--text-xl);
                @include breakpoint-up('large'){
                    font-size: var(--text-2xl);
                }
            }

            p {
                font-size: var(--text-3xl);

                @include breakpoint-up('large'){
                    font-size: var(--text-4xl);
                }
            }

        }
    }

   
}
