@use '../abstract' as *;

.cards {
	display: flex;
	flex-direction: column;
	gap: rem(24);

	&__btn {
		align-self: center;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		gap: rem(28);

		> * {
			flex-grow: 1;
		}

		@include breakpoint-up('large') {
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.card {
	//Color Scheme
	--_clr-bg: hsl(var(--primary-50));
	--_clr-title: hsl(var(--accent-600));
	--_clr-para: hsl(var(--primary-900));

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: rem(16);
	padding: rem(30);
	max-width: 100%;
	background-color: var(--_clr-bg);
	border-radius: var(--rounded);
	box-shadow: var(--shadow-xl);

	@include breakpoint-up('medium') {
		max-width: 70%;
		height: rem(500);
	}
	@include breakpoint-up('large') {
		max-width: rem(410);
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: rem(16);
	}

	&__img {
		height: rem(108);
		img {
			width: 100%;
			height: 100%;
		}

		@include breakpoint-up('medium') {
			height: rem(135);
		}
	}

	&__title {
		color: var(--_clr-title);
		font-size: var(--text-2xl);
		font-weight: var(--font-semibold);
	}

	&__para {
		color: var(--_clr-para);
		font-size: var(--text-base);
		font-weight: var(--font-semibold);

		@include breakpoint-up('medium') {
			font-size: var(--text-sm);
		}

		@include breakpoint-up('large') {
			font-size: var(--text-base);
		}
	}

	&--light {
		//Color Scheme
		--_clr-bg: hsl(var(--neutral-50));
		--_clr-title: hsl(var(--accent-600));
		--_clr-para: hsl(var(--primary-900));
	}
}
