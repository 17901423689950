@use "../abstract" as *;

.imagesGrid {
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    grid-gap: rem(40);
    position: relative;

    @include breakpoint-up("medium") {
        grid-template-columns: repeat(4, 1fr);
    }

    &__wrapper {
        overflow: hidden;
        height: 0;
        width: 100%;
        padding: 50% 0;
        // Padding will control the height, will always be perfectly square regardless of the image width;
        position: relative;
        transition: opacity 250ms ease-in-out;
        animation: fade-in 250ms ease-in-out both;
        animation-delay: 500ms;
        img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            max-width: 150%;
            object-fit: cover;
            object-position: center;
            top: -50px;
            left: 0;
            scale: 1;
            opacity: 0.8;
            transition:
                scale 250ms ease-in-out,
                opacity 250ms ease-in-out;
        }
        &:hover img {
            opacity: 1;
        }

        .label {
            position: absolute;
            width: 100%;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.7);
            font-weight: 600;
            font-size: rem(10);
            bottom: 0;
            transform: translateY(20px);
            opacity: 0;
            visibility: 0;
            transition: transform 250ms ease-in-out;

            @include breakpoint-up("large") {
                font-size: rem(14);
            }
        }

        &:hover .label {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }

        &:hover {
            opacity: 1;
        }

        &:hover img {
            scale: 1.1;
        }
    }

    &__btn {
        grid-column: 1/-1;
        justify-self: center;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
