@use "../abstract" as *;

.modal {
    z-index: 99999;

    &__backdrop {
        position: fixed;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100dvh;
        background-color: rgba(0, 0, 0, 0.8);
    }

    &__imgWrapper {
        --_w: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(5);
        width: 60dvw;
        height: 40dvh;
        animation: zoom-in 600ms ease-in both;

        @include breakpoint-up("medium") {
            height: calc(100% - 40px);
        }

        img {
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
            border: 3px solid white;
        }

        .label {
            font-size: var(--text-xs);
            color: white;

            @include breakpoint-up("medium") {
                font-size: var(--text-base);
            }
        }
    }

    &__icon {
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: white;
        font-size: rem(50);
        transition: opacity 250ms ease-in-out;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            opacity: 1;
        }

        &:focus,
        &:focus-visible,
        &:active {
            border-radius: 50%;
            background-color: hsla(var(--primary-200), 0.3);
        }
    }

    &__close {
        position: fixed;
        top: 15px;
        right: 15px;
        font-size: rem(30);
    }
    &__navigation-left,
    &__navigation-right {
        display: grid;
        place-content: center;
        top: 50%;
        transform: translateY(-50%);
        aspect-ratio: 1;

        @include breakpoint-up("medium") {
            width: 50px;
        }
    }

    &__navigation-left {
        left: 20px;
    }

    &__navigation-right {
        right: 20px;
    }
}

@keyframes zoom-in {
    0% {
        scale: 0;
    }
    100% {
        scale: 1;
    }
}
