:root {
    // Border Radius
    --rounded-sm: 8px;
    --rounded: 12px;
    --rounded-lg: 16px;

    // Box Shadows
    --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    --shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.6), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    --shadow-md: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    --shadow-xl: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    --shadow-2xl: 0px 45px 50px -12px rgba(0, 0, 0, 0.25);
    --shadow-sm-inner: 0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset;
    --shadow-md-inner: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
}

// Config
html {
    font-family: var(--font-mont);
    font-size: 16px;
    background-color: hsl(var(--neutral-50));
    color: var(--primary--900);
}

h1 {
    font-size: var(--text-5xl);
    line-height: var(--leading-tight);
}

h2 {
    font-size: var(--text-4xl);
    line-height: var(--leading-tight);
}

h3 {
    font-size: var(--text-3xl);
    line-height: var(--leading-tight);
}

h4 {
    font-size: var(--text-2xl);
    line-height: var(--leading-normal);
}

h5 {
    font-size: var(--text-xl);
    line-height: var(--leading-normal);
}

h6 {
    font-size: var(--text-lg);
    line-height: var(--leading-normal);
}

p {
    font-size: var(--text-base);
    line-height: var(--leading-loose);
}

/* Hiding class, making content visible only to screen readers but not visually */
/* "sr" meaning "screen-reader" */
.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

button,
input {
    /* 1 */
    overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
    /* 1 */
    text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */
button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    appearance: button;
}

ul[data-role="arrow-list"] {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        display: flex;
        font-size: inherit;
        color: inherit;
        padding: 6px 0;

        &::before {
            content: "\ec33";
            font-family: "boxicons";
            padding: 0 8px;
            font-size: 16px;
        }
    }
}
