@use "../abstract" as *;

//source:https://www.youtube.com/watch?v=rO4gsHdJJ5k

:root {
    --_offset-container: -10%;
    --_mb-title: 10px;
    --_logos-py: 20px;
    --_logo-container-height: 50px;
    --_w-logo: 40px;
    --_animation-duration: 35s;
    --_gap: 90px;
    --_wrapperWidth: 500%;
    --_offsetWidth: -250%;
}

@include breakpoint-up("medium") {
    :root {
        --_offset-container: -20%;
        --_mb-title: 30px;
        --_logos-py: 20px;
        --_logo-container-height: 90px;
        --_w-logo: 80px;
        --_wrapperWidth: 250%;
        --_offsetWidth: -125%;
    }
}

.brand-carousel {
    background-color: hsla(var(--primary-50), 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: rem(16) 0;
    border-radius: var(--rounded);
    position: absolute;
    bottom: var(--_offset-container);
    left: 50%;
    z-index: 10;
    transform: translateY(var(--_offset-container)) translateX(-50%);
    box-shadow: var(--shadow-md-inner);

    @supports (backdrop-filter: blur(10px)) {
        background-color: hsla(var(--neutral-500), 0.4);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    .title {
        --_color: hsl(var(--neutral-900));
        color: var(--_color);
        font-size: clamp(var(--text-xs), -0.1238rem + 2.4272vw, var(--text-lg));
        font-weight: var(--font-semibold);
        margin: 0;
        margin-bottom: var(--_mb-title);
        position: relative;

        &::after {
            content: "";
            width: rem(101);
            height: 1px;
            background-color: var(--_color);
            position: absolute;
            bottom: rem(-2);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .brandLogos {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: var(--_logo-container-height);

        .logosWrapper {
            position: absolute;
            width: var(--_wrapperWidth);
            top: 50%;
            left: 0;
            animation: slide var(--_animation-duration) linear infinite;
            transform: translate(0, -50%);
        }

        &:hover .logosWrapper {
            animation-play-state: paused;
        }

        .logos {
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            list-style-type: none;
            white-space: nowrap;
            width: 50%;
            padding: 0;
            margin: 0;

            .logo {
                width: var(--_w-logo);
                img {
                    min-width: 100%;
                }
            }
        }
    }
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: var(--_offsetWidth);
    }
}
