@use "../abstract" as *;

.contact {
    // Themes
    --_bg-color: hsl(var(--primary-50));
    --_clr-heading: hsl(var(--primary-400));
    --_clr-para: hsl(var(--primary-900));

    &--light {
        --_bg-color: hsl(var(--primary-50));
        --_clr-heading: hsl(var(--primary-400));
        --_clr-para: hsl(var(--primary-900));
    }

    &--dark {
        --_bg-color: hsl(var(--primary-800));
        --_clr-heading: hsl(var(--primary-50));
        --_clr-para: hsl(var(--primary-50));
    }

    border-radius: var(--rounded);
    box-shadow: var(--shadow-2xl);

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        background-color: var(--_bg-color);
        padding: rem(12);
        border-radius: var(--rounded);

        @include breakpoint-up("large") {
            grid-template-columns: repeat(2, 1fr);
            padding: rem(42);
            gap: rem(26);
        }
    }

    &__form {
        position: relative;
    }

    &__details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .wrapper {
            display: grid;
            grid-template-columns: 1fr;
            padding: rem(32) 0;
        }

        @include breakpoint-up("large") {
            .wrapper {
                grid-template-columns: repeat(2, 1fr);
                padding-top: 0;
            }
        }
    }

    &__detail {
        padding: rem(12) 0;

        h3 {
            font-size: var(--text-lg);
            color: var(--_clr-heading);
            font-weight: var(--font-semibold);
            text-transform: uppercase;
        }
        p {
            font-size: var(--text-sm);
            color: var(--_clr-para);
        }

        .custom-para {
            p {
                display: grid;
                grid-template-columns: 45px 10px 150px;
            }


            p span:nth-child(1) {
                color: var(--_clr-heading);
                font-weight: 600;
            }
            p span:nth-child(2) {
                color: var(--_clr-heading);
                font-weight: 600;
            }
        }
    }

    &__map {
        padding-bottom: 50%;
        position: relative;
        height: rem(450);

        iframe {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: rem(8);
        }

        @include breakpoint-up("large") {
            // height: rem(438);
            height: 100%;
        }
    }
}

.form {
    // Themes
    --_bg-color: hsl(var(--primary-50));
    --_label-color: hsl(var(--primary-900));
    --_input-color: hsl(var(--neutral-400));
    --_para-color: hsl(var(--primary-900));

    &--dark {
        --_bg-color: hsl(var(--primary-800));
        --_label-color: hsl(var(--primary-50));
        --_input-color: hsl(var(--neutral-100));
        --_para-color: hsl(var(--primary-50));
    }

    &--light {
        --_bg-color: hsl(var(--primary-50));
        --_label-color: hsl(var(--primary-900));
        --_input-color: hsl(var(--neutral-400));
        --_para-color: hsl(var(--primary-900));
    }

    background-color: inherit;
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(16);

    @include breakpoint-up("large") {
        grid-template-columns: repeat(2, 1fr);

        .w-full {
            grid-column: 1/3;
        }
    }

    fieldset {
        border: none;
        outline: none;
        padding: 0;
    }

    label {
        display: inline-block;
        color: var(--_label-color);
        font-size: var(--text-base);
        font-weight: var(--font-semibold);
        margin-bottom: rem(4);
    }

    input,
    textarea,
    select {
        display: block;
        width: 100%;
        border: rem(1) solid var(--_input-color);
        border-radius: var(--rounded-sm);
        padding: rem(12);
        color: var(--_para-color);

        &:focus,
        &:focus-visible,
        &:active {
            border: rem(2) solid var(--_para-color);
        }

        &::placeholder {
            color: var(--_input-color);
            font-size: var(--text-sm);
        }
    }

    select {
        --_color: var(--_label-color);
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--_bg-color);
        background-image: linear-gradient(45deg, transparent 50%, var(--_color) 50%),
            linear-gradient(135deg, var(--_color) 50%, transparent 50%);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 16px) calc(1em + 2px);
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
        font-size: var(--text-sm);
        color: var(--_clr-para);
    }

    textarea {
        height: rem(230);
        resize: none;

        @include breakpoint-up("large") {
            height: rem(268);
        }
    }

    fieldset[data-valid="false"] {
        --_input-color: var(--error-600);
        --_para-color: var(--error-600);

        .error {
            color: var(--_para-color);
            font-size: var(--text-xs);
            font-weight: 600;
        }
    }

    fieldset[required] label::after {
        content: "*";
        margin-left: 5px;
        color: inherit;
        outline: blue;
    }

    fieldset[data-valid="true"] {
        --_input-color: var(--success-600);
        --_para-color: var(--success-600);

        .error {
            color: var(--_para-color);
            font-size: var(--text-sm);
        }
    }
}
