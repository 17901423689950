@use "../abstract" as *;

.pageBanner.container {
    padding: 0;
}

.pageBanner {
    --_clr-overlay: rgba(255 255 255 / 60%);
    --_clr-gradient: linear-gradient(90deg, rgb(248, 250, 252) 40%, rgba(255, 255, 255, 0) 100%);

    position: relative;
    height: rem(133);

    &__image {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;

        img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            object-position: center;
        }

        .overlay {
            position: absolute;
            background: var(--_clr-overlay);
            z-index: 3;
            height: 100%;
            width: 100%;
        }

        @include breakpoint-up("large") {
            .overlay {
                background: var(--_clr-gradient);
            }
        }
    }

    // background: linear-gradient(0deg, var(--_clr-overlay), var(--_clr-overlay));
    // background-position: right -150px center;
    // background-size: 160dvw;
    // background-repeat: no-repeat;
    // height: rem(133);
    // width: 100%;

    &__wrapper {
        height: 100%;
    }

    &__header {
        --_clr-font: hsl(var(--primary-800));

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(10);
        width: 100%;
        height: inherit;

        h1 {
            font-size: var(--text-4xl);
            color: var(--_clr-font);
        }

        &::after {
            content: "";
            height: rem(5);
            width: rem(94);
            background-color: var(--_clr-font);
            display: block;
        }
    }

    @include breakpoint-up("large") {
        background-size: rem(1850);
        background-position: right -250px center;
        height: rem(227);

        &__header {
            align-items: flex-start;

            h1 {
                font-size: rem(64);
                color: var(--_clr-font);
            }

            &::after {
                width: rem(157);
            }
        }
    }
}
