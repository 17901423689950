@use "../abstract" as *;

.navbar {
    background: linear-gradient(180deg, hsl(var(--primary-50)) 55%, rgba(255, 255, 255, 0) 100%);
    // position: absolute;
    width: 100%;
    max-width: 96.875rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    @include breakpoint-down("medium") {
    }

    --nav-height: rem(143);
    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 9999;
        min-height: rem(143);
    }

    &__logo {
        max-width: rem(250);
        max-height: rem(93);
        @include breakpoint-down("medium") {
            max-width: rem(200);
            min-width: rem(127);
        }
    }

    &__menu {
        display: flex;
        flex-wrap: wrap;
        gap: rem(32);

        @include breakpoint-down("medium") {
            position: absolute;
            background-color: hsla(var(--primary-50), 0.7);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            justify-content: center;
            gap: rem(16);
            padding: rem(16);
            margin: 0 rem(16);
            right: 0;
            top: calc(rem(143) - rem(30));
            min-width: rem(50);
            border-radius: var(--rounded-sm);
            -webkit-transition: all 600ms cubic-bezier(0.64, 0.11, 0.64, 0.86);
            transition: all 600ms cubic-bezier(0.64, 0.11, 0.64, 0.86);

            @supports (backdrop-filter: blur(9.4px)) {
                // background-color: transparent;
                -webkit-backdrop-filter: blur(9.4px);
                backdrop-filter: blur(9.4px);
            }

            &[data-visible="false"] {
                -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                visibility: visible;
            }

            &[data-visible="true"] {
                -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
                clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
                visibility: none;
            }
        }

        @include breakpoint-down("small") {
            left: 0;
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: rem(15);
        padding: 0;
        font-weight: var(--font-semibold);
        gap: rem(32);

        @include breakpoint-down("medium") {
            flex-direction: column;
            width: 100%;
        }
    }

    &__link > a[data-role="navigation"] {
        color: hsl(var(--neutral-500));
        position: relative;
        transition: all 200ms ease-in-out;

        /* stroke */
        --color: hsl(var(--primary-600));
        &:after {
            content: "";
            position: absolute;
            width: 0%;
            height: rem(2);
            left: 50%;
            bottom: rem(-6);
            transform: translateX(-50%);
            background-color: var(--color);
            transition: all 300ms ease-in-out;
        }

        &:hover,
        &:hover:after {
            color: var(--color);
            width: 100%;
        }
    }
    // Active Link
    &__link > a[aria-current="page"] {
        font-size: rem(16);
        color: var(--color);
        &:after {
            width: 100%;
        }
    }
}

//-- Start Nav Menu Toggle --//
.navbar__toggle {
    font-family: "boxicons";
    display: inline-block;
    vertical-align: middle;
    color: hsl(var(--primary-700));
    font-weight: 900;
    font-size: rem(26);
    transition: all 200ms ease-in-out;

    &:hover,
    &:focus,
    &:focus-visible {
        font-size: rem(27);
        color: hsl(var(--primary-800));
    }

    @include breakpoint-up("large") {
        display: none;
    }
}

.navbar__toggle[aria-expanded="false"]::before {
    content: "\eb61";
}
.navbar__toggle[aria-expanded="true"]::before {
    content: "\ec8d";
}
// -- End Nav Menu Toggle --//
