@use "../abstract" as *;

.footer {
    --_clr-bg: hsl(var(--primary-50));
    --_logo: url("../../assets/logo/logo_dark.png");
    --_clr-font: hsl(var(--primary-900));
    --_clr-hover: hsl(var(--primary-600));
    --_clr-inactive: hsl(var(--neutral-500));
    --_clr-line: hsl(var(--primary-200));
    --_clr-bottom: hsl(var(--neutral-500));

    .container {
        background-color: var(--_clr-bg);
    }

    &__wrapper {
        padding: rem(24) 0;
    }
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(38);
        border-bottom: 1px solid var(--_clr-line);

        @include breakpoint-up("large") {
            flex-direction: row;
            align-content: center;
            justify-content: space-between;
            gap: 0;
        }
    }

    &__logo {
        height: rem(110);
        width: 100%;

        .logo {
            background-image: var(--_logo);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: rem(108);

            @include breakpoint-up("large") {
                background-position: left;
                height: rem(101);
            }
        }
    }

    &__navlinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--text-base);
        padding: 0;
        font-weight: var(--font-semibold);
        gap: rem(37);

        @include breakpoint-up("large") {
            flex-direction: row;
            justify-content: center;
            gap: rem(30);
            width: 100%;
        }
    }

    // Inactive Link
    &__navlink > a[data-role="navigation"] {
        color: var(--_clr-inactive);
        position: relative;
        transition: all 150ms ease-in-out;

        // /* stroke */
        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 0%;
        //     height: rem(2);
        //     left: 50%;
        //     bottom: rem(-6);
        //     transform: translateX(-50%);
        //     background-color: var(--_clr-hover);
        //     transition: all 300ms ease-in-out;
        // }

        &:hover,
        &:hover:after {
            color: var(--_clr-hover);
            width: 100%;
        }
    }

    // Active Link
    &__navlink > a[aria-current="page"] {
        font-size: rem(17);
        color: var(--_clr-hover);
        &:after {
            width: 100%;
        }
    }

    &__sociallinks {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        gap: rem(20);
        font-size: rem(22);

        @include breakpoint-up("large") {
            justify-content: flex-end;
            gap: rem(27);
        }

        a {
            color: var(--_clr-font);
            transition: all 150ms ease-in-out;

            &:hover {
                color: var(--_clr-hover);
            }
        }
    }

    &__bottom {
        padding-top: rem(24);
        display: flex;
        flex-direction: column;
        align-items: center;

        * {
            font-size: var(--text-xs);
        }

        @include breakpoint-up("large") {
            flex-direction: row;
            justify-content: space-between;
            gap: rem(27);
        }
    }

    &__copyright {
        display: flex;
        flex-direction: row;
        align-content: center;
        gap: rem(2);
        color: var(--_clr-bottom);
        font-size: inherit;

        span {
            font-size: rem(16);
        }
    }

    &__signature {
        * {
            color: hsl(var(--neutral-500));
        }

        a:hover {
            text-decoration: underline;
            text-underline-offset: 5px;
        }
    }

    // Themes
    &--dark {
        --_clr-bg: hsl(var(--primary-900));
        --_logo: url("../../assets/logo/logo_light.png");
        --_clr-font: hsl(var(--primary-50));
        --_clr-hover: hsl(var(--primary-400));
        --_clr-inactive: hsl(var(--primary-50));
        --_clr-line: hsl(var(--primary-50));
        --_clr-bottom: hsl(var(--neutral-100));
    }
}
